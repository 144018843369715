import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useGDPRPageContent = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Gdpr" } }
        language: { code: { eq: EN } }
      ) {
        gdprPageFields {
          pageTitle
          pageContent
        }
      }
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Gdpr" } }
        language: { code: { eq: RO } }
      ) {
        gdprPageFields {
          pageTitle
          pageContent
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return pages[language]?.gdprPageFields || wpPageRo?.gdprPageFields
}
